.header {
    background-color: #3F3F3F;
    width: 100%;
    height: 96px;
    .container {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
        padding-top: 25px;
        img {
            width: 100%;
            max-width: 480px;
        }
        .user {
            color: #ffffff;
            display: inline-block;
            float: right;
            .greeting {
                margin-top: 15px;
                font-size: 1.2rem;
                display: inline-block;
            }
            button {
                display: inline-block;
                background-color: #7deadd;
                color: #000000;
                margin-top: -5px;
                margin-left: 15px;
                border-radius: 25px;
                text-transform: none;
                width: 120px;
                font-size: 1rem;
                font-weight: 600;
                &:hover {
                    background-color: #57dbc7;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .header {
        height: 56px;
        .container {
            padding: 18px;
            width: calc(100% - 36px);
            max-width: unset;
            img {
                max-width: 160px;
            }
            .user {
                margin-top: -12px;
                .greeting {
                    font-size: 0.6rem;
                }
                button {
                    margin-top: 0px;
                    margin-left: 10px;
                    width: 68px;
                    font-size: 0.45rem;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .header {
        height: 64px;
        .container {
            padding: 20px;
            width: calc(100% - 40px);
            max-width: unset;
            img {
                max-width: 240px;
            }
            .user {
                .greeting {
                    margin-top: 3px;
                    font-size: 0.9rem;
                }
                button {
                    margin-top: -5px;
                    margin-left: 10px;
                    width: 80px;
                    font-size: 0.7rem;
                }
            }
        }
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .header {
        height: 72px;
        .container {
            padding: 25px;
            width: calc(100% - 50px);
            max-width: unset;
            img {
                max-width: 280px;
            }
            .user {
                .greeting {
                    margin-top: 5px;
                    font-size: 1rem;
                }
                button {
                    margin-top: -5px;
                    margin-left: 10px;
                    width: 96px;
                    font-size: 0.8rem;
                }
            }
        }
    }
}