.privacy {
	background-color: #E5E2E1;
	min-height: calc(100vh - 356px);
	.content {
		margin: 0 auto;
		width: 100%;
		max-width: 1280px;
		.back-link {
			font-size: 1.5rem;
			font-weight: 600;
			color: #37817a;
			cursor: pointer;
			span {
				text-decoration: underline;
			}
		}
		.backtotop-link {
			font-size: 1.5rem;
			font-weight: 600;
			text-decoration: underline;
			color: #37817a;
			cursor: pointer;
		}
	}
}