.footer {
    background-color: #3F3F3F;
    width: 100%;
    height: 120px;
    margin-top: 100px;
    .container {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
        padding: 25px 0;
        .link {
            display: inline-block;
            color: #ffffff;
            cursor: pointer;
            margin-top: 30px;
            margin-right: 15px;
            font-size: 1.2rem;
            &:hover,&.active {
                color: #7deadd;
            }
        }
        .copyright {
            display: inline-block;
            color: #ffffff;
            margin-top: 30px;
            font-size: 1.2rem;
            float: right;
        }
    }
}

@media screen and (max-width: 480px) {
    .footer {
        height: 72px;
        .container {
            width: calc(100% - 36px);
            max-width: unset;
            padding: 18px;
            .link {
                margin-top: 0px;
                margin-right: 10px;
                font-size: 0.8rem;
            }
            .copyright {
                font-size: 0.8rem;
                margin-top: 10px;
                float: left;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .footer {
        height: 96px;
        .container {
            width: calc(100% - 40px);
            max-width: unset;
            padding: 20px;
            .link {
                margin-top: 25px;
                margin-right: 15px;
                font-size: 0.85rem;
            }
            .copyright {
                font-size: 0.85rem;
                margin-top: 25px;
            }
        }
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .footer {
        .container {
            width: calc(100% - 50px);
            max-width: unset;
            padding: 25px;
            .link {
                margin-top: 25px;
                margin-right: 15px;
                font-size: 1rem;
            }
            .copyright {
                font-size: 1rem;
                margin-top: 25px;
            }
        }
    }
}
