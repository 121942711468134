.contact {
	background-color: #E5E2E1;
	min-height: calc(100vh - 356px);
	.content {
		margin: 0 auto;
		width: 100%;
		max-width: 1280px;
		.back-link {
			font-size: 1.5rem;
			font-weight: 600;
			color: #37817a;
			cursor: pointer;
			span {
				text-decoration: underline;
			}
		}
		label.Mui-focused {
			color: inherit !important;
		}
		button {
			float: right;
			background-color: #7deadd;
			color: #000000;
			border-radius: 25px;
			text-transform: none;
			width: 120px;
			font-size: 1rem;
			font-weight: 600;
			cursor: pointer;
		}
	}
}