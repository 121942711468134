.navigator-content {
    background-color: #E5E2E1;
    min-height: calc(100vh - 356px);
    .nav {
        width: 100%;
        height: 72px;
        background-color: #CCCAC8;
        display: inline-block;
        .container {
            margin: 0 auto;
            width: 100%;
            max-width: 1280px;
            .step1,
            .step2 {
                margin-top: 25px;
                display: inline-block;
                cursor: pointer;
                &.active {
                    font-weight: 600;
                }
            }
            .search {
                display: inline-block;
                float: right;
                background-color: #ffffff;
                border-radius: 25px;
                margin-top: 15px;
                fieldset {
                    border-radius: 25px;
                }
                .search-btn {
                    border: 2px solid #CCCAC8;
                    width: 26px;
                    height: 26px;
                    border-radius: 26px;
                    color: #CCCAC8;
                    margin-right: -6px;
                    cursor: pointer;
                }
                .Mui-focused {
                    .search-btn {
                        border-color: #CCCAC8;
                        //color: #ed6c02;
                        color: #CCCAC8;
                    }
                }
            }
        }
    }
    .content {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
        .step-title {
            font-size: 2rem;
            font-weight: 600;
            padding: 25px 0;
        }
        .tab-title {
            &.active {
                background-color: #37817a;
            }
        }
        .tab-detail {
            &.active {
                background-color: #ebfbf9;
            }
        }
        .list-title {
            font-size: 1.2rem;
            font-weight: 600;
            padding-top: 5px;
            padding-left: 5px;
            width: 780px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .list-content {
            padding: 15px;
            font-size: 1rem;
            line-height: 1.5rem;
            .select {
                background-color: #7deadd !important;
                border: none;
                color: #000000;
                border-radius: 25px;
                font-size: 0.8rem;
                font-weight: 600;
                line-height: 1.2rem;
                text-transform: none;
                width: 96px;
                margin-top: 15px;
                &:hover,
                &:visited,
                &:active,
                &:focus {
                    background-color: #57dbc7 !important;
                }
            }
        }
        .helpinfo {
            background-color: #CCCAC8;
            padding: 25px;
            border-radius: 5px;
            .title {
                font-size: 1.2rem;
                font-weight: 700;
            }
            .details {
                margin-top: 15px;
                font-size: 0.8rem;
                font-weight: 600;
                line-height: 1.2rem;
                .more {
                    margin-top: 5px;
                    font-weight: 100;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .topic-bar {
            background-color: #37817a;
            color: #fff;
            font-size: 1.2rem;
            font-weight: 600;
            margin-top: 25px;
            padding: 10px 25px;
            div {
                width: 1180px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        hr.solid {
            border: 1px solid #CCCAC8;
        }
        .list-loglaw {
            padding: 15px;
            height: 360px;
            overflow-y: auto;
        }
        .goback {
            display: inline-block;
            background-color: #7deadd;
            color: #000000;
            margin-top: 15px;
            float: right;
            border-radius: 25px;
            text-transform: none;
            width: 160px;
            font-size: 0.8rem;
            font-weight: 600;
            &:hover {
                background-color: #57dbc7;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .navigator-content {
        .nav {
            .container {
                width: calc(100% - 36px);
                max-width: unset;
                padding: 0 18px;
                .step1,
                .step2 {
                    margin-top: 25px;
                    font-size: 0.8rem;
                }
                .search {
                    margin-top: 15px;
                    width: 120px;
                }
            }
        }
        .content {
            width: calc(100% - 36px);
            max-width: unset;
            padding: 0 18px;
            .step-title {
                font-size: 1.6rem;
                padding: 15px 0;
            }
            .list-title {
                width: 240px;
                font-size: 1rem;
            }
            .topic-bar {
                div {
                    width: 180px;
                }
            }
            .goback {
                margin-top: 10px;
                width: 120px;
                font-size: 0.5rem;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .navigator-content {
        .nav {
            .container {
                width: calc(100% - 40px);
                max-width: unset;
                padding: 0 20px;
                .step1,
                .step2 {
                    margin-top: 25px;
                    font-size: 1rem;
                }
                .search {
                    margin-top: 15px;
                    width: 180px;
                }
            }
        }
        .content {
            width: calc(100% - 40px);
            max-width: unset;
            padding: 0 20px;
            .step-title {
                font-size: 1.6rem;
                padding: 15px 0;
            }
            .list-title {
                width: 360px;
            }
            .topic-bar {
                div {
                    width: 360px;
                }
            }
            .goback {
                margin-top: 10px;
                width: 130px;
                font-size: 0.6rem;
            }
        }
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .navigator-content {
        .nav {
            .container {
                width: calc(100% - 50px);
                max-width: unset;
                padding: 0 25px;
                .step1,
                .step2 {
                    margin-top: 25px;
                    font-size: 1rem;
                }
                .search {
                    margin-top: 15px;
                    width: 240px;
                }
            }
        }
        .content {
            width: calc(100% - 50px);
            max-width: unset;
            padding: 0 25px;
            .list-title {
                width: 460px;
            }
            .topic-bar {
                div {
                    width: 480px;
                }
            }
            .goback {
                margin-top: 10px;
                width: 150px;
                font-size: 0.7rem;
            }
        }
    }
}