.grid-container {
  width: 100%;
	display: flex;
	.item {
		display: flex; 
		justify-content: center; 
		align-items: center;
		position: relative;
		padding-top: 50px;
	}
	.col-1 {
		width: 8.33%;
	}
	.col-2 {
		width: 16.66%;
	}
	.col-3 {
		width: 24.99%;
	}
	.col-4 {
		width: 33.32%;
	}
	.col-5 {
		width: 41.65%;
	}
	.col-6 {
		width: 49.995%;
	}
	.col-7 {
		width: 58.35%;
	}
	.col-8 {
		width: 66.68%;
	}
	.col-9 {
		width: 75.01%;
	}
	.col-10 {
		width: 83.34%;
	}
	.col-11 {
		width: 91.67%;
	}
	.col-12 {
		width: 100%;
	}
	.item1 {
		position: relative;
		padding-top: 50px;
	}
}

.flowchart {
	.flowchart-start {
		background-color: #c3d9d7;
		color: #000000;
		padding: 10px 15px;
		font-size: 1.5rem;
		p{
			padding: 0;
		}
	}

	.flowchart-section {
		.flowchart-badge {
			background-color: #37817a;
			color: #ffffff;
			font-weight: 600;
			font-size: 1.2rem;
			width: 48px;
			height: 48px;
			border-radius: 48px;
			display: flex; 
			justify-content: center; 
			align-items: center;
		}
		.question-icon {
			width: 24px;
			height: 24px;
			border: 2px solid #37817a;
			color: #37817a;
			border-radius: 24px;
			float: right;
			display: flex; 
			justify-content: center; 
			align-items: center;
			cursor: pointer;
		}
		.line1 {
			position: absolute;
			height: calc(50% - 24px); 
			border-left: 1px solid #37817a; 
			top: 26px;
		}
		.line2 {
			position: absolute;
			width: calc(50% - 24px);
			border-top: 1px solid #37817a;
			right: 0;
		}
		.line3 {
			position: absolute;
			height: calc(50% - 24px);
			border-left: 1px solid #37817a;
			bottom: -26px;
		}
		.line4 {
			position: absolute;
			height: 50px;
			border-left: 1px solid #37817a;
			top: 0px;
			left: 50px;
		}
		.line5 {
			position: absolute;
			height: 50px;
			border-left: 1px dashed #37817a;
			top: 0px;
			left: 50px;
			.YN {
				border: 1px solid #37817a;
				background-color: #f5f5f5;
				color: #37817a;
				font-size: 1rem;
				font-weight: 600;
				width: 48px;
				height: 20px;
				border-radius: 15px;
				text-align: center;
				position: relative;
				left: -24px;
				top: 10px;
				padding-top: 3px;
			}
		}
		.line6 {
			position: absolute;
			height: 100%;
			border-left: 1px dashed #37817a;
			top: 0px;
			left: 50%;
			.YN {
				border: 1px solid #37817a;
				background-color: #f5f5f5;
				color: #37817a;
				font-size: 1rem;
				font-weight: 600;
				width: 48px;
				height: 20px;
				border-radius: 15px;
				text-align: center;
				position: relative;
				left: -24px;
				top: 10px;
				padding-top: 3px;
			}
		}
		.line7 {
			position: absolute;
			height: 50px;
			border-left: 1px dashed #37817a;
			bottom: -50px;
			left: 50px;
		}
		.line8 {
			position: absolute;
			height: 50px;
			border-left: 1px solid #37817a;
			bottom: -50px;
			left: 50px;
		}
		.item {
			.line5 {
				height: calc(50% - 24px); 
				border-left: 1px dashed #37817a; 
				top: 26px;
				left: unset;
			}
			.line6 {
				height: calc(100% + 48px); 
				border-left: 1px dashed #37817a; 
			}
		}
		.flowchart-step {
			background-color: #ebfbf9;
			border-radius: 5px;
			padding: 15px 25px;
			font-size: 1.2rem;
			font-weight: 300;
			display: flex;
			.flowchart-content {
				width: 91.66%;
				line-height: 1.8rem;
				.flowchart-title {
					font-weight: 900;
					font-size: 1.3rem;
				}
			}
			.flowchart-help {
				width: 8.33%;
			}
		}
		.flowchart-boolean {
			background-color: #ebfbf9;
			border-radius: 5px;
			padding: 10px 15px;
			font-size: 1.2rem;
			font-weight: 600;
			.flowchart-title {
				font-weight: 900;
				font-size: 1.3rem;
			}
		}
		.flowchart-result{
			background-color: #37817a;
			font-size: 1.2rem;
			font-weight: 600;
			font-size: 1.2rem;
			color: #ffffff;
			border-radius: 5px;
			padding: 10px 15px;
		}
	}
}
.moreInfo {
	.title {
		font-size: 1.2rem;
		font-weight: 900;
	}
	.link {
		background-color: rgba(0, 0, 0, 0) !important;
		color: #37817a !important;
		border-radius: 25px;
		border: 1px solid #37817a;
		font-weight: 600;
		text-transform: none;
		text-decoration: none;
		padding: 5px 25px;
		margin-top: 60px;
	}
	.close-btn {
		width: 24px;
		height: 24px;
		border: 2px solid #37817a;
		color: #37817a;
		border-radius: 24px;
		cursor: pointer;
		position: absolute;
		top: 15px;
		right: 15px;
		.close-icon {
			font-size: 2.6rem;
			font-weight: 600;
			margin-top: -15px;
			margin-left: 4px;
		}
	}
}