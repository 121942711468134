.login_container {
    background: url(../../assets/img/GreyScreen.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 480px;
    position: relative;
    .logo {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
        padding-top: 25px;
        img {
            width: 100%;
            max-width: 480px;
        }
    }
    .container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .title {
            font-size: 2.5rem;
            font-weight: 600;
            text-align: center;
        }
        .username {
            padding: 5px;
            fieldset {
                border-radius: 25px;
            }
        }
        .password {
            padding: 5px;
            fieldset {
                border-radius: 25px;
            }
        }
        .submit {
            padding-top: 15px;
            .MuiFormControlLabel-label {
                font-weight: 600;
                cursor: pointer;
            }
            button {
                background-color: #7deadd;
                color: #000000;
                float: right;
                border-radius: 25px;
                text-transform: none;
                width: 120px;
                font-size: 1rem;
                font-weight: 600;
                &:hover {
                    background-color: #57dbc7;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .login_container {
        .logo {
            padding: 18px;
            width: calc(100vw - 36px);
            img {
                max-width: 180px;
            }
        }
        .container {
            padding: 25px !important;
            .title {
                font-size: 2rem;
            }
            .submit {
                padding-top: 15px;
                .MuiFormControlLabel-label {
                    font-size: 0.8rem;
                }
                button {
                    width: 96px;
                    font-size: 0.8rem;
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 600px) {
    .login_container {
        .logo {
            padding: 20px;
            width: calc(100vw - 40px);
            img {
                max-width: 240px;
            }
        }
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .login_container {
        .logo {
            padding: 25px;
            width: calc(100vw - 50px);
            img {
                max-width: 280px;
            }
        }
    }
}

@media (-webkit-device-pixel-ratio: 1.25) {
.login_container{
        min-height: 125vh;
    }
}
@media (-webkit-device-pixel-ratio: 1.5) {
.login_container{
        min-height: 150vh;
    }
}