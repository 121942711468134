.question-content {
    padding: 25px;
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
    #sv-nav-start {
        margin-left: auto;
        margin-right: 0;
        .startBtn {
            background-color: #7deadd !important;
            border-color: none;
            box-shadow: none;
            color: #000000 !important;
            font-size: 1rem;
            padding: 0 1.2rem;
            &:hover {
                background-color: #57dbc7 !important;
            }
        }
    }
    .sd-action-bar {
        margin-left: auto;
        margin-right: 0;
        width: 260px;
        margin-top: 25px;
        #sv-nav-next,
        #sv-nav-prev,
        #sv-nav-complete {
            margin-left: auto;
            margin-right: 0;
            .prevBtn,
            .nextBtn,
            .completeBtn {
                background-color: #7deadd !important;
                border-color: none;
                box-shadow: none;
                color: #000000 !important;
                font-size: 1rem;
                padding: 0 1.2rem;
                width: 110px;
                &:hover {
                    background-color: #57dbc7 !important;
                }
            }
        }
    }
    .question-content {
        padding: 0 !important;
    }
    .sd-row,
    .sd-action-bar {
        padding: 25px;
    }
    .sd-row:has(.sd-question--answered) {
        background-color: #E5E2E1 !important;
    }
    .sd-boolean__control:focus~.sd-boolean__switch {
        box-shadow: inset 0 0 0 2px #7deadd !important;
    }
    .sd-boolean__thumb-text {
        color: #37817a !important;
    }
    .sd-completedpage button {
        background-color: #7deadd !important;
        border-color: none;
        box-shadow: none;
        color: #000000 !important;
        font-size: 1rem;
        border: 0;
        padding: 4px 10px;
        cursor: pointer;
        border-radius: 4px;
        &:hover {
            background-color: #57dbc7 !important;
        }
    }
    .export-btn {
        background-color: #7deadd !important;
        border-color: none;
        box-shadow: none;
        color: #000000 !important;
        font-size: 1rem;
        border: 0;
        padding: 4px 10px;
        cursor: pointer;
        border-radius: 4px;
        &:hover {
            background-color: #57dbc7 !important;
        }
    }
    .more-btn {
        background-color: rgba(0, 0, 0, 0) !important;
        color: #37817a !important;
        border-radius: 25px;
        border: 1px solid #37817a;
        font-weight: 600;
        text-transform: none;
        margin-top: 60px;
    }
}

@media screen and (max-width: 480px) {
    .question-content {
        .sd-action-bar {
            width: 100%;
        }
        .sd-body {
            .sd-body__page {
                min-width: 100%;
                .sd-row {
                    div:first-child {
                        min-width: 100% !important;
                    }
                }
            }
        }
    }
}